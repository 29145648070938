import React from "react"

import PropTypes from "prop-types"

const Header = ({ siteTitle }) => (
  <header>
    <span>{siteTitle}</span>
    <span className="links">Available in
      <a href="https://www.qoo10.sg/shop/harlowaugiko" target="_blank" rel="noopener noreferrer">
        Qoo10</a> and
      <a href="https://shopee.sg/augiko" target="_blank" rel="noopener noreferrer">
        Shopee</a>
      </span>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
